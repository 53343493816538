<template>
  <BlogSummary :list-site="listSite" :tagName="tagName"></BlogSummary>
</template>

<script>
import BlogSummary from "@/components/index/BlogSummary";

export default {
  name: "Tag",
  components: {BlogSummary},
  data() {
    return {
      listSite: "/tag/tag-search",
      tagName: this.$route.params.tagName
    }
  },
  //注意：next放行之后路由才会改变，应在路由改变后再取this.$route.params赋值，否则取到的是旧值
  beforeRouteUpdate(to,from,next){
    if (this.$route.name === "tag") {
      next()
      this.tagName = this.$route.params.tagName
    }
  }
}
</script>

<style scoped>

</style>